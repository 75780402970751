<script setup lang="ts">

withDefaults(defineProps<{
    href: string;
    target?: string;
    color?: "monochrome" | "color"
}>(), {
    color: "color",
    target: "_self"
})

</script>
<template>
    <a :href="href" :target="target"
        :class="[color === 'monochrome' ? '' : 'bg-primary text-primary-content shadow-sm hover:bg-primary-focus', 'cursor-pointer rounded-md px-3.5 py-2.5 text-base whitespace-nowrap font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-focus']">
        <slot />
    </a>
</template>