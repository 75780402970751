<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import ButtonComponent from './ButtonComponent.vue';
import RingtailLogo from '../assets/logos/ringtail.svg'
import { CONFIG } from '../config'

const navigation = [
    { name: 'Our Team', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'Blog', href: '/blog' },
]

const mobileMenuOpen = ref(false)
</script>
<template>
    <header class="sticky inset-x-0 top-0 z-50 bg-base-100">
        <nav class="grid grid-cols-3 relative items-center px-4 py-6 lg:px-8" aria-label="Global">
            <div class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="justify-start">
                <a href="/">
                    <span class="sr-only">Ringtail Labs</span>
                    <img class="w-72" :src="RingtailLogo.src" alt="Ringtail Labs" />
                </a>
            </div>
            <div class="hidden lg:flex lg:gap-x-12 justify-center">
                <a v-for="item in navigation" :key="item.name" :href="item.href"
                    class="text-lg font-semibold leading-6 text-gray-900 border-b-4 transition-colors border-base-100 hover:border-primary">{{
                        item.name
                    }}</a>
            </div>
            <div class="flex justify-end">
                <ButtonComponent :href="CONFIG.schedulingUrl" target="_blank"
                    class="text-xs px-2 py-1 sm:px-3.5 sm:py-2.5 sm:text-sm lg:text-lg">
                    Meet <span class="hidden md:inline">with Us</span>
                </ButtonComponent>
            </div>
        </nav>
        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-50" />
            <DialogPanel
                class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-base-100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <a href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only">Ringtail Labs</span>
                        <!-- <RingtailLogoVue class="h-8 w-auto"></e> -->
                    </a>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <a v-for="item in navigation" :key="item.name" :href="item.href"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                {{ item.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>